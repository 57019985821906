<template>
  <div>
    <!-- 英文 -->
    <div class="main" v-show="lang == 'en'">
      <div class="box">
        <div class="top">
          <div class="text1">Legal Notices</div>
          <div class="text2">Last revision date: 2022-08-20</div>
        </div>
        <div class="content">
          <p>
            This website {{ this.basicConfig.CompanyNameEnglish }} owns, manages
            and maintains. Please read the following terms carefully before
            using this website. Using this website indicates that you have
            clearly understood and accepted the following terms. If you cannot
            accept the following terms, please do not use this website.
          </p>
          <p>
            This website reserves the right to correct, modify and update this
            statement at any time.
          </p>
          <p>
            <strong
              ><span style="font-size: 14pt;"
                >1. Intellectual Property Statement</span
              ></strong
            >
          </p>
          <p>
            All rights (including copyright, trademark rights, etc.) of all
            products, technologies, software, programs, data and other
            information (including text, icons, pictures, photos, audio, video,
            graphics, color combinations, layout design, etc.) involved in this
            website Patent rights, trade secrets and other related rights),
            unless otherwise specified, are owned by
            {{ this.basicConfig.CompanyNameEnglish }} and protected by relevant
            laws and regulations such as intellectual property rights. Any
            unauthorized use is illegal and will be subject to law Bear the
            corresponding legal responsibilities.
          </p>
          <p>
            This website respects intellectual property rights and opposes and
            combats infringements of intellectual property rights. If you, as
            the right holder, believe that the content of this website infringes
            your legitimate rights and interests, you can submit a written
            request to this website and provide valid identification, ownership,
            and Links and detailed proof of infringement, this website will deal
            with it in a timely manner in accordance with the corresponding laws
            and regulations and the rules of this website after receiving your
            qualified notice.
          </p>
          <p>
            <strong
              ><span style="font-size: 14pt;"
                >2. Information release statement</span
              ></strong
            >
          </p>
          <p>
            1. If you publish information through this website, you can only do
            so in a lawful and compliant manner for lawful purposes, and abide
            by the following terms:
          </p>
          <p>
            (1) All information published on this website must not violate the
            relevant laws and regulations of the state and local governments on
            Internet network information security;
          </p>
          <p>
            (2) Explain all the published information to users, and
            independently assume all legal responsibilities arising therefrom;
          </p>
          <p>
            (3) This website has the right to modify, delete or do other
            appropriate processing for all information that you publish that is
            illegal or does not comply with the rules of this website.
          </p>
          <p>
            2. For other users who publish information and launch products or
            provide services on this website, the user and you clearly agree on
            their respective rights and obligations in the relevant agreement or
            legal document, and the user independently assumes legal
            responsibility to you.
          </p>
          <p>
            <strong><span style="font-size: 14pt;">3. Disclaimer</span></strong>
          </p>
          <p>
            In any of the following situations, this website does not assume any
            legal responsibility:
          </p>
          <p>
            1. This website provides your personal information in accordance
            with the provisions of laws and regulations or other normative legal
            documents or the requirements of regulatory agencies; any third
            party uses your personal information in accordance with the
            circumstances listed in the relevant legal documents signed with
            you, thereby Disputes arising;
          </p>
          <p>
            2. As you inform others of your account, password and other
            transaction passwords or share your registered account with others,
            any personal information leakage or personal information leakage
            caused by other reasons other than this website; and the
            aforementioned personal information leakage caused you Loss of
            property;
          </p>
          <p>
            3. You or you and any third party engage in false transactions,
            fraud, money laundering or other violations of laws and regulations
            through this website;
          </p>
          <p>4. Economic losses caused by your own reasons;</p>
          <p>
            5. The website temporarily stops providing all or part of the
            products or services due to computer virus intrusion, hacker attack,
            or government control;
          </p>
          <p>6. Any consequences caused by force majeure;</p>
          <p>
            7. The exemptions listed in relevant legal documents such as the
            rules of this website or agreement terms and statements.
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
    <!-- 中文 -->
    <div class="main" v-show="lang == 'zh'">
      <div class="box">
        <div class="top">
          <div class="text1">法律声明</div>
          <div class="text2">最新修订日期：2022-08-20</div>
        </div>
        <div class="content">
          <p>
            本网站{{
              this.basicConfig.CompanyNameEnglish
            }}拥有、管理和维护。使用本网站前请您仔细阅读下列各个条款，使用本网站则表明您已经明确知晓并接受下列条款，如果您不能接受下列条款，请不要使用本网站。<br />
            本网站保留随时更正、修改、更新本声明的权利。
          </p>
          <p>
            <span style="font-size: 14pt;"
              ><strong>一、知识产权声明</strong></span
            >
          </p>
          <p>
            本网站涉及的所有产品、技术、软件、程序、数据及其他信息（包括文字、图标、图片、照片、音频、视频、图表、色彩组合、版面设计等）的所有权利（包括版权、商标权、专利权、商业秘密及其他相关权利），除有特别说明外，均归{{
              this.basicConfig.CompanyNameEnglish
            }}公司所有，并受知识产权等相关法律法规的保护，任何未经授权使用的行为均为违法行为，将依法承担相应的法律责任。<br />
            本网站尊重知识产权，反对并打击侵犯知识产权的行为，如您作为权利人认为本网站内容侵害您合法权益的，可以以书面形式向本网站提出，并提供有效身份证明、权属证明、具体链接及详细侵权情况证明，本网站将在收到您合格通知后依据相应的法律法规及本网站规则及时予以处理。
          </p>
          <p>
            <strong
              ><span style="font-size: 14pt;">二、信息发布声明</span></strong
            >
          </p>
          <p>
            1、如您通过本网站发布信息，只能出于合法目的以合法合规方式进行，并遵守以下条款的约束：<br />
            （1）在本网站发布的所有信息，均不得违反国家及地方政府关于互联网网络信息安全等相关法律法规的规定；<br />
            （2）对发布的所有信息向用户进行解释，并独立承担由此引发的一切法律责任；<br />
            （3）对您发布的不合法或不符合本网站规则的所有信息，本网站有权进行修改、删除或做其他适当处理。<br />
            2、对于其他用户在本网站发布信息并推出产品或提供服务的，由该用户与您在相关协议或法律文件中明确约定各自的权利义务，并由该用户独立向您承担法律责任。
          </p>
          <p>
            <span style="font-size: 14pt;"><strong>三、免责声明</strong></span>
          </p>
          <p>
            出现以下任一情况，本网站不承担任何法律责任：<br />
            1、本网站根据法律法规或其他规范性法律文件的规定或监管机构的要求提供您的个人信息；任何第三方根据与您签署的相关法律文件所列明的情况使用您的个人信息，由此所产生的纠纷；<br />
            2、由于您将账户、密码等交易口令告知他人或与他人共享注册帐户，由此导致任何个人信息的泄漏，或其他非本网站原因导致的个人信息泄漏；及因前述个人信息泄露而导致您的财产损失；<br />
            3、您或您与任何第三方通过本网站从事虚假交易、诈骗、洗钱或其他违法违规行为的；<br />
            4、您自身原因导致的经济损失；<br />
            5、由于电脑病毒入侵、黑客攻击、或政府管制而造成的本网站暂时性停止提供全部或部分产品或服务的；<br />
            6、因不可抗力导致的任何后果；<br />
            7、本网站规则或协议条款及声明等相关法律文件中列明的免责情形。
          </p>
        </div>
      </div>
    </div>
    <!-- 泰语 -->
    <div class="main" v-show="lang == 'ty'">
      <div class="box">
        <div class="top">
          <div class="text1">ประกาศทางกฎหมาย</div>
          <div class="text2">วันที่แก้ไขครั้งล่าสุด: 2022-08-20</div>
        </div>
        <div class="content">
          <p>
            เว็บไซต์นี้{{ this.basicConfig.CompanyNameEnglish }}เป็นเจ้าของ
            จัดการ และบำรุงรักษา
            โปรดอ่านข้อกำหนดต่อไปนี้อย่างละเอียดก่อนที่จะใช้เว็บไซต์นี้
            การใช้เว็บไซต์นี้แสดงว่าคุณเข้าใจอย่างชัดเจนและยอมรับข้อกำหนดต่อไปนี้
            หากคุณไม่สามารถยอมรับข้อกำหนดต่อไปนี้ได้ โปรดอย่าใช้เว็บไซต์นี้
            <br />
            เว็บไซต์นี้ขอสงวนสิทธิ์ในการแก้ไข ปรับปรุง
            และปรับปรุงข้อความนี้ได้ตลอดเวลา
          </p>
          <p>
            <span style="font-size: 14pt;"
              ><strong>1. คำชี้แจงทรัพย์สินทางปัญญา</strong></span
            >
          </p>
          <p>
            สิทธิ์ทั้งหมด (รวมถึงลิขสิทธิ์ สิทธิ์ในเครื่องหมายการค้า
            สิทธิ์ในสิทธิบัตร ความลับทางการค้าและสิทธิ์อื่นๆ ที่เกี่ยวข้อง)
            เว้นแต่จะระบุไว้เป็นอย่างอื่น เป็นของ{{
              this.basicConfig.CompanyNameEnglish
            }}เป็นของบริษัทและได้รับการคุ้มครองโดยกฎหมายและข้อบังคับที่เกี่ยวข้อง
            เช่น สิทธิ์ในทรัพย์สินทางปัญญา การใช้งานใด ๆ
            โดยไม่ได้รับอนุญาตถือเป็นการกระทำที่ผิดกฎหมายและจะต้องรับผิดชอบทางกฎหมายที่สอดคล้องกันตามกฎหมาย<br />
            เว็บไซต์นี้เคารพสิทธิ์ในทรัพย์สินทางปัญญา
            และต่อต้านและต่อต้านการละเมิดทรัพย์สินทางปัญญา
            หากคุณในฐานะผู้ถือสิทธิ์เชื่อว่าเนื้อหาของเว็บไซต์นี้ละเมิดสิทธิ์และผลประโยชน์ทางกฎหมายของคุณ
            คุณสามารถส่งเนื้อหาดังกล่าวไปยังเว็บไซต์นี้อย่างเป็นลายลักษณ์อักษรและระบุตัวตนที่ถูกต้องได้
            ใบรับรอง ใบรับรองความเป็นเจ้าของ ลิงก์เฉพาะ
            และใบรับรองการละเมิดโดยละเอียด
            เว็บไซต์นี้จะจัดการกับมันอย่างทันท่วงทีตามกฎหมายและข้อบังคับที่เกี่ยวข้องและกฎของเว็บไซต์นี้หลังจากได้รับการแจ้งที่มีคุณสมบัติเหมาะสมจากคุณ
          </p>
          <p>
            <strong
              ><span style="font-size: 14pt;"
                >2. แถลงการณ์เผยแพร่ข้อมูล</span
              ></strong
            >
          </p>
          <p>
            1. หากคุณเผยแพร่ข้อมูลผ่านเว็บไซต์นี้
            คุณจะสามารถทำได้ในลักษณะที่ถูกกฎหมายและสอดคล้องเพื่อวัตถุประสงค์ที่ถูกต้องตามกฎหมายเท่านั้น
            และปฏิบัติตามข้อกำหนดต่อไปนี้:<br />
            (1)
            ข้อมูลทั้งหมดที่เผยแพร่บนเว็บไซต์นี้จะต้องไม่ละเมิดกฎหมายและข้อบังคับที่เกี่ยวข้องของรัฐและรัฐบาลท้องถิ่นเกี่ยวกับความปลอดภัยของข้อมูลเครือข่ายอินเทอร์เน็ต<br />
            (2) อธิบายข้อมูลทั้งหมดที่เผยแพร่แก่ผู้ใช้
            และรับผิดชอบทางกฎหมายทั้งหมดที่เกิดขึ้นจากข้อมูลดังกล่าวโดยอิสระ<br />
            (3) เว็บไซต์นี้มีสิทธิ์แก้ไข ลบ หรือดำเนินการอื่น ๆ
            ที่เหมาะสมสำหรับข้อมูลทั้งหมดที่คุณเผยแพร่ซึ่งผิดกฎหมายหรือไม่เป็นไปตามกฎของเว็บไซต์นี้
            <br />
            2.
            สำหรับผู้ใช้รายอื่นที่เผยแพร่ข้อมูลบนเว็บไซต์นี้และเปิดตัวผลิตภัณฑ์หรือให้บริการ
            ผู้ใช้และคุณจะตกลงอย่างชัดเจนเกี่ยวกับสิทธิ์และหน้าที่ของตนในข้อตกลงหรือเอกสารทางกฎหมายที่เกี่ยวข้อง
            และผู้ใช้จะรับผิดชอบทางกฎหมายต่อคุณโดยอิสระ
          </p>
          <p>
            <span style="font-size: 14pt;"
              ><strong>3. ข้อจำกัดความรับผิดชอบ</strong></span
            >
          </p>
          <p>
            ในสถานการณ์ใดๆ ต่อไปนี้ เว็บไซต์นี้ไม่รับผิดชอบทางกฎหมายใดๆ:<br />
            1.
            เว็บไซต์นี้ให้ข้อมูลส่วนบุคคลของคุณตามบทบัญญัติของกฎหมายและข้อบังคับหรือเอกสารทางกฎหมายเชิงบรรทัดฐานอื่น
            ๆ หรือข้อกำหนดของหน่วยงานกำกับดูแล บุคคลที่สามใด ๆ
            ใช้ข้อมูลส่วนบุคคลของคุณตามเงื่อนไขที่ระบุไว้ในเอกสารทางกฎหมายที่เกี่ยวข้องที่ลงนามกับคุณ
            จึงมีข้อพิพาทเกิดขึ้น<br />
            2.
            การรั่วไหลของข้อมูลส่วนบุคคลที่เกิดจากการที่คุณแจ้งให้ผู้อื่นทราบถึงบัญชี
            รหัสผ่าน และรหัสผ่านการทำธุรกรรมอื่น ๆ ของคุณ
            หรือแบ่งปันบัญชีที่ลงทะเบียนของคุณกับผู้อื่น
            หรือการรั่วไหลของข้อมูลส่วนบุคคลใด ๆ ที่เกิดจากสาเหตุอื่น ๆ
            ที่ไม่ได้เกิดจากเว็บไซต์นี้
            และการรั่วไหลของข้อมูลส่วนบุคคลของคุณที่เกิดจาก
            โดยการรั่วไหลของข้อมูลส่วนบุคคลดังกล่าวทำให้ทรัพย์สินเสียหาย<br />
            3. คุณหรือคุณและบุคคลที่สามมีส่วนร่วมในการทำธุรกรรมที่เป็นเท็จ
            การฉ้อโกง การฟอกเงิน หรือกิจกรรมที่ผิดกฎหมายอื่น ๆ
            ผ่านทางเว็บไซต์นี้<br />
            4. ความสูญเสียทางเศรษฐกิจเกิดจากเหตุผลของคุณเอง<br />
            5.
            เว็บไซต์นี้หยุดให้บริการทั้งหมดหรือบางส่วนชั่วคราวเนื่องจากการบุกรุกของไวรัสคอมพิวเตอร์
            การโจมตีของแฮ็กเกอร์ หรือการควบคุมของรัฐบาล<br />
            6. ผลกระทบใดๆ ที่เกิดจากเหตุสุดวิสัย<br />
            7. ข้อยกเว้นที่ระบุไว้ในเอกสารทางกฎหมายที่เกี่ยวข้อง เช่น
            กฎของเว็บไซต์นี้หรือข้อกำหนดและข้อความในข้อตกลง
          </p>
        </div>
      </div>
    </div>
    <!-- 日语 -->
    <div class="main" v-show="lang == 'jp'">
      <div class="box">
        <div class="top">
          <div class="text1">法的通知</div>
          <div class="text2">最終改訂日: 2022-08-20</div>
        </div>
        <div class="content">
          <p>
            このサイト{{
              this.basicConfig.CompanyNameEnglish
            }}所有、管理、維持されています。
            このウェブサイトをご利用になる前に、以下の条件をよくお読みください.
            このウェブサイトを使用することは、あなたが以下の条件を明確に理解し、同意したことを意味します.
            以下の条件に同意できない場合は、このウェブサイトを使用しないでください.
            <br />
            このウェブサイトは、この声明をいつでも修正、変更、更新する権利を留保します。
          </p>
          <p>
            <span style="font-size: 14pt;"
              ><strong>1. 知的財産に関する声明</strong></span
            >
          </p>
          <p>
            すべての権利（著作権、商標権、特許権、営業秘密およびその他の関連する権利を含みます）は、別段の定めがない限り、当社に帰属します。{{
              this.basicConfig.CompanyNameEnglish
            }}これは会社が所有し、知的財産権などの関連法規によって保護されており、無断使用は違法であり、法律に従って相応の法的責任を負うものとします。
            <br />
            このウェブサイトは、知的財産権を尊重し、知的財産権の侵害に反対し、闘います.
            権利所有者として、このウェブサイトのコンテンツがあなたの法的権利と利益を侵害していると信じる場合は、書面でこのウェブサイトに提出し、有効な身元を提供することができます.証明書、所有権証明書、特定のリンク、および詳細な侵害証明書、このウェブサイトは、適切な通知を受け取った後、対応する法律および規制およびこのウェブサイトの規則に従ってタイムリーに処理します。
          </p>
          <p>
            <strong
              ><span style="font-size: 14pt;"
                >第二に、情報公開声明</span
              ></strong
            >
          </p>
          <p>
            1. この Web
            サイトを通じて情報を公開する場合は、正当な目的のために合法的かつ準拠した方法でのみ行うことができ、次の条件を順守してください。<br />
            (1) このウェブサイトに掲載されているすべての情報は、インターネット
            ネットワークの情報セキュリティに関する国および地方自治体の関連法規に違反してはなりません。<br />
            (2)
            公開されたすべての情報をユーザーに説明し、そこから生じるすべての法的責任を独自に負う;<br />
            (3)
            このウェブサイトは、あなたが公開するすべての情報について、違法またはこのウェブサイトの規則に準拠していないものを修正、削除、またはその他の適切な処理を行う権利を有します。
            <br />
            2.
            このウェブサイトで情報を公開し、製品を発売またはサービスを提供する他のユーザーについては、ユーザーとあなたは、関連する契約または法的文書でそれぞれの権利と義務に明確に同意し、ユーザーは独自にあなたに対する法的責任を負うものとします。
          </p>
          <p>
            <span style="font-size: 14pt;"><strong>3. 免責事項</strong></span>
          </p>
          <p>
            次のいずれかの状況において、この Web
            サイトは法的責任を負いません。<br />
            1.
            このウェブサイトは、法律および規制またはその他の規範的な法的文書の規定または規制機関の要件に従って、お客様の個人情報を提供します;
            第三者は、お客様が署名した関連する法的文書に記載されている条件に従って、お客様の個人情報を使用します.
            <br />
            2.
            お客様が、ご自身のアカウント、パスワード、その他の取引パスワードを他人に知らせたり、登録したアカウントを他人と共有したりすることによる個人情報の漏えい、または当ウェブサイトに起因しないその他の理由による個人情報の漏えい、およびお客様の個人情報の漏えいに起因する一切前述の個人情報漏えいによる物的損害;<br />
            3.
            あなたまたはあなたと第三者が、このウェブサイトを通じて虚偽の取引、詐欺、マネー
            ロンダリング、またはその他の違法行為に関与した;<br />
            4. 自己の理由による経済的損失;<br />
            5. このウェブサイトは、コンピュータ
            ウイルスの侵入、ハッカーの攻撃、または政府の管理により、製品またはサービスの全部または一部の提供を一時的に停止します。<br />
            6. 不可抗力による結果;<br />
            7.
            このウェブサイトのルールや契約条項、声明など、関連する法的文書に記載されている免除。
          </p>
        </div>
      </div>
    </div>
    <!-- 韩语 -->
    <div class="main" v-show="lang == 'hy'">
      <div class="box">
        <div class="top">
          <div class="text1">법적 고지</div>
          <div class="text2">마지막 개정일: 2022-08-20</div>
        </div>
        <div class="content">
          <p>
            이 지역{{ this.basicConfig.CompanyNameEnglish }}소유, 관리 및 유지
            관리합니다. 이 웹사이트를 사용하기 전에 다음 조건을 주의 깊게
            읽으십시오. 이 웹사이트를 사용하는 것은 귀하가 다음 조건을 명확하게
            이해하고 수락했음을 의미합니다. 다음 조건을 수락할 수 없다면 이
            웹사이트를 사용하지 마십시오. <br />
            이 웹사이트는 언제든지 이 진술을 수정, 수정 및 업데이트할 권리를
            보유합니다.
          </p>
          <p>
            <span style="font-size: 14pt;"
              ><strong>1. 지적 재산권 선언문</strong></span
            >
          </p>
          <p>
            달리 명시되지 않는 한 모든 권리(저작권, 상표권, 특허권, 영업 비밀 및
            기타 관련 권리 포함)는 모두
            {{ this.basicConfig.CompanyNameEnglish }} 회사의 소유이며 지적재산권
            등 관련법규에 의해 보호받고 있으며, 무단도용시 불법이며 이에
            상응하는 법적인 법적책임을 집니다. <br />
            이 사이트는 지적 재산권을 존중하고 지적 재산권 침해에 반대하고
            투쟁합니다.권리자로서 이 사이트의 콘텐츠가 귀하의 법적 권리와 이익을
            침해한다고 생각하는 경우 서면으로 이 사이트에 제출하고 유효한 신분을
            제공할 수 있습니다. 인증서, 소유권 인증서, 특정 링크 및 자세한 침해
            인증서, 이 웹사이트는 귀하의 적격 통지를 받은 후 해당 법률 및 규정과
            이 웹사이트의 규칙에 따라 적시에 처리합니다.
          </p>
          <p>
            <strong
              ><span style="font-size: 14pt;">二、信息发布声明</span></strong
            >
          </p>
          <p>
            1. 이 웹사이트를 통해 정보를 게시하는 경우 적법한 목적을 위해
            합법적이고 규정을 준수하는 방식으로만 게시할 수 있으며 다음 조건을
            준수해야 합니다.<br />
            (1) 본 사이트에 게시된 모든 정보는 인터넷망 정보보안에 관한 국가 및
            지방자치단체의 관련법규를 위반하지 않아야 합니다.<br />
            (2) 공개된 모든 정보를 사용자에게 설명하고 그로부터 발생하는 모든
            법적 책임을 독립적으로 집니다.<br />
            (3) 이 웹사이트는 불법적이거나 이 웹사이트의 규칙을 준수하지 않는
            귀하가 게시한 모든 정보에 대해 수정, 삭제 또는 기타 적절한 처리를
            수행할 권리가 있습니다. <br />
            2. 이 웹사이트에 정보를 게시하고 제품을 출시하거나 서비스를 제공하는
            다른 사용자에 대해 사용자와 귀하는 관련 계약 또는 법률 문서에서
            각자의 권리와 의무에 대해 명확하게 합의하고 사용자는 독립적으로
            귀하에 대한 법적 책임을 집니다.
          </p>
          <p>
            <span style="font-size: 14pt;"><strong>3. 면책조항</strong></span>
          </p>
          <p>
            다음과 같은 상황에서 본 웹사이트는 법적 책임을 지지 않습니다.<br />
            1. 이 웹사이트는 법률 및 규정 또는 기타 규범적 법률 문서 또는 규제
            기관의 요구 사항에 따라 귀하의 개인 정보를 제공하며, 제3자는 귀하와
            서명한 관련 법률 문서에 나열된 조건에 따라 귀하의 개인 정보를
            사용합니다. 따라서 발생하는 분쟁;<br />
            2. 자신의 계정, 비밀번호 및 기타 거래 비밀번호를 타인에게 알리거나
            등록된 계정을 타인과 공유하여 개인정보가 유출되거나 본 사이트가
            원인이 아닌 다른 사유로 인해 개인정보가 유출된 경우 및 이로 인한
            개인정보 유출 상기 개인정보 유출로 인한 재산상의 손해<br />
            3. 귀하 또는 귀하와 제3자가 이 웹사이트를 통해 허위 거래, 사기, 자금
            세탁 또는 기타 불법 활동에 관여하는 경우<br />
            4. 귀하의 사유로 인한 경제적 손실<br />
            5. 컴퓨터 바이러스 침입, 해커의 공격 또는 정부의 통제로 인해 본
            웹사이트는 제품 또는 서비스의 전부 또는 일부 제공을 일시적으로
            중단합니다.<br />
            6. 불가항력으로 인한 결과<br />
            7. 이 웹사이트의 규칙 또는 계약 조건 및 진술과 같은 관련 법률 문서에
            나열된 면제.
          </p>
        </div>
      </div>
    </div>
    <!-- 印尼语 -->
    <div class="main" v-show="lang == 'yn'">
      <div class="box">
        <div class="top">
          <div class="text1">Pemberitahuan Hukum</div>
          <div class="text2">Tanggal revisi terakhir: 20-08-2022</div>
        </div>
        <div class="content">
          <p>
            situs ini {{ this.basicConfig.CompanyNameEnglish }} dimiliki,
            dikelola, dan dipelihara. Harap baca ketentuan berikut dengan
            saksama sebelum menggunakan situs web ini. Menggunakan situs web ini
            menunjukkan bahwa Anda telah memahami dengan jelas dan menerima
            ketentuan berikut. Jika Anda tidak dapat menerima ketentuan berikut,
            harap jangan gunakan situs web ini. <br />
            Situs web ini berhak untuk memperbaiki, memodifikasi, dan
            memperbarui pernyataan ini kapan saja.
          </p>
          <p>
            <span style="font-size: 14pt;"
              ><strong>1. Pernyataan Kekayaan Intelektual</strong></span
            >
          </p>
          <p>
            Semua hak (termasuk hak cipta, hak merek dagang, hak paten, rahasia
            dagang, dan hak terkait lainnya), kecuali ditentukan lain, adalah
            milik{{ this.basicConfig.CompanyNameEnglish }}Itu dimiliki oleh
            perusahaan dan dilindungi oleh undang-undang dan peraturan yang
            relevan seperti hak kekayaan intelektual. Setiap penggunaan yang
            tidak sah adalah ilegal dan akan memikul tanggung jawab hukum yang
            sesuai menurut hukum. <br />
            Situs web ini menghormati hak kekayaan intelektual, dan menentang
            serta memerangi pelanggaran kekayaan intelektual. Jika Anda, sebagai
            pemegang hak, meyakini bahwa konten situs web ini melanggar hak dan
            kepentingan hukum Anda, Anda dapat mengirimkannya ke situs web ini
            secara tertulis dan memberikan identitas yang sah sertifikat,
            sertifikat kepemilikan, Tautan spesifik, dan sertifikat pelanggaran
            terperinci, situs web ini akan menanganinya tepat waktu sesuai
            dengan undang-undang dan peraturan terkait serta aturan situs web
            ini setelah menerima pemberitahuan yang memenuhi syarat dari Anda.
          </p>
          <p>
            <strong
              ><span style="font-size: 14pt;"
                >2. Pernyataan rilis informasi</span
              ></strong
            >
          </p>
          <p>
            1. Jika Anda mempublikasikan informasi melalui situs web ini, Anda
            hanya dapat melakukannya dengan cara yang sah dan patuh untuk tujuan
            yang sah, dan mematuhi persyaratan berikut:<br />
            (1) Semua informasi yang dipublikasikan di situs web ini tidak boleh
            melanggar undang-undang dan peraturan yang relevan dari pemerintah
            negara bagian dan lokal tentang keamanan informasi jaringan
            Internet;<br />
            (2) Menjelaskan kepada pengguna semua informasi yang dirilis, dan
            secara mandiri menanggung semua tanggung jawab hukum yang timbul
            darinya;<br />
            (3) Situs web ini berhak mengubah, menghapus, atau melakukan
            pemrosesan lain yang sesuai untuk semua informasi yang Anda
            publikasikan yang ilegal atau tidak mematuhi aturan situs web ini.
            <br />
            2. Untuk pengguna lain yang mempublikasikan informasi di situs web
            ini dan meluncurkan produk atau menyediakan layanan, pengguna dan
            Anda akan dengan jelas menyetujui hak dan kewajiban masing-masing
            dalam perjanjian atau dokumen hukum yang relevan, dan pengguna akan
            secara mandiri memikul tanggung jawab hukum kepada Anda.
          </p>
          <p>
            <span style="font-size: 14pt;"><strong>3. Penafian</strong></span>
          </p>
          <p>
            Dalam salah satu situasi berikut, situs web ini tidak memikul
            tanggung jawab hukum apa pun:<br />
            1. Situs web ini menyediakan informasi pribadi Anda sesuai dengan
            ketentuan undang-undang dan peraturan atau dokumen hukum normatif
            lainnya atau persyaratan dari badan pengatur; pihak ketiga mana pun
            menggunakan informasi pribadi Anda sesuai dengan ketentuan yang
            tercantum dalam dokumen hukum terkait yang ditandatangani dengan
            Anda, sehingga timbul perselisihan;<br />
            2. Kebocoran informasi pribadi apa pun yang disebabkan oleh Anda
            memberi tahu orang lain tentang akun, kata sandi, dan kata sandi
            transaksi lainnya atau berbagi akun terdaftar Anda dengan orang
            lain, atau kebocoran informasi pribadi apa pun yang disebabkan oleh
            alasan lain yang tidak disebabkan oleh situs web ini; dan kebocoran
            informasi pribadi Anda disebabkan oleh kerusakan properti kebocoran
            informasi pribadi tersebut;<br />
            3. Anda atau Anda dan pihak ketiga mana pun terlibat dalam transaksi
            palsu, penipuan, pencucian uang, atau aktivitas ilegal lainnya
            melalui situs web ini;<br />
            4. Kerugian ekonomi yang disebabkan oleh alasan Anda sendiri;<br />
            5. Situs web ini untuk sementara berhenti menyediakan semua atau
            sebagian dari produk atau layanan karena invasi virus komputer,
            serangan peretas, atau kontrol pemerintah;<br />
            6. Segala akibat yang disebabkan oleh force majeure;<br />
            7. Pengecualian yang tercantum dalam dokumen hukum yang relevan
            seperti aturan situs web ini atau syarat dan pernyataan perjanjian.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CurrencyMobileNewIndex',

  data() {
    return {}
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection'])
  },
  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.main {
  padding-top: 20px;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 60px;
  background-color: #f8f8f8;
  align-items: center;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
  .box {
    width: 1120px;
    min-height: 747px;
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    .top {
      width: 100%;
      height: 75px;
      padding-top: 17px;
      box-sizing: border-box;
      border-bottom: 1px solid #eaecef;
      text-align: center;
      .text1 {
        margin-bottom: 8px;
        color: #1e1e1e;
        font-size: 18px;
      }
      .text2 {
        color: #989898;
        font-size: 12px;
      }
    }
    .content {
      width: 100%;
      padding: 20px 30px;
      box-sizing: border-box;
      letter-spacing: 1px;
      line-height: 16.8px;
      word-wrap: break-word;
      text-align: left;
      color: #000;
      p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }
    }
  }
}
</style>
